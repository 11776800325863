import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store/index";
import router from "./router/";
import Auth from "../src/middleware/";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
const GOOGLE_MAPS_APIKEY = "AIzaSyAH_Pt2We-VEQunHe6lXfzydv3gabyWgU4";
import socketio from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import VueHtmlToPaper from "vue-html-to-paper";
export const SocketInstance = socketio("https://paxrify.com/", {
  transports: ["websocket"],
});

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketInstance,
  })
);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: GOOGLE_MAPS_APIKEY, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  version: "...", // Optional
  language: "...", // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

Vue.use(Auth);
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.forVisitors)) {
    if (store.getters.getUser) {
      next({
        path: "/Dashboard",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/",
        query: { redirect: to.path },
      });
    } else next();
  } else next();
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
