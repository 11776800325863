import {
  GetAllRider,
  GetAllStaffs,
  GetRiderWalletHistory,
  AddRider,
  ActivateAndDeactivateRider,
  freezeRiderWallet,
  RiderOfflinePayout,
  ActivateAndDisableStaff,
  GetAvailableStaffPosition,
  GetRoles,
  CreateStaffPosition,
  AddStaff,
  ChangeStaffRole,
  StaffActivities,
} from "../APIs";

import axios from "axios";

const state = {
  Riders: [],
  staffWalletTransactionTotalItems: 1,
  staffWalletTransaction: [],
  Staffs: [],
  StaffPosition: [],
  Roles: [],
  StaffActivities: [],
};

const getters = {
  getRiders(state) {
    return state.Riders;
  },
  staffWalletTransactionTotalItems(state) {
    return state.staffWalletTransactionTotalItems;
  },
  staffWalletTransaction(state) {
    return state.staffWalletTransaction;
  },
  getStaffs(state) {
    return state.Staffs;
  },
  StaffPosition(state) {
    return state.StaffPosition;
  },
  Roles(state) {
    return state.Roles;
  },
  Activities(state) {
    return state.StaffActivities;
  },
};

const mutations = {
  setRoles(state, payload) {
    state.Roles = payload;
  },
  setRiders(state, payload) {
    state.Riders = payload;
  },
  setStaffWalletTransactionTotalItems(state, payload) {
    state.staffWalletTransactionTotalItems = payload;
  },
  setStaffWalletTransaction(state, payload) {
    state.staffWalletTransaction = payload;
  },
  setStaffs(state, payload) {
    state.Staffs = payload;
  },
  setStaffPosition(state, payload) {
    state.StaffPosition = payload;
  },
  setStaffActivities(state, payload) {
    state.StaffActivities = payload;
  },
};

const actions = {
  getAllRider({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllRider}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setRiders", res.data.Riders);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetRiderWalletHistory({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${GetRiderWalletHistory}?page=${page}`,
        {
          riderId: payload.riderId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setStaffWalletTransaction", res.data.wallets);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  AddRider({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddRider}`,
        {
          firstName: payload.firstName,
          phoneNumber: payload.phoneNumber,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.route.push({ name: "ManageRider" });
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  activateAndDisableRider({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ActivateAndDeactivateRider}`,
        {
          action: payload.action,
          riderId: payload.riderId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("getAllRider", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  freezeRiderWallet({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${freezeRiderWallet}`,
        {
          riderId: payload.riderId,
          action: payload.action,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("getAllRider", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  RiderOfflinePayout({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${RiderOfflinePayout}`,
        {
          riderId: payload.riderId,
          amount: payload.amount,
          walletId: payload.walletId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("GetRiderWalletHistory", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getAllStaffs({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllStaffs}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setStaffs", res.data.Staffs);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  activateAndDisableStaff({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ActivateAndDisableStaff}`,
        {
          action: payload.action,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("getAllStaffs", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getAvailableStaffPosition({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAvailableStaffPosition}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setStaffPosition", res.data.StaffPosition);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getRoles({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetRoles}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setRoles", res.data.Roles);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  createStaffPosition({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${CreateStaffPosition}`,
        {
          roles: payload.selected,
          positionName: payload.positionName,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then(() => {
        payload.page = 1;
        dispatch("getAvailableStaffPosition", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  AddStaff({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddStaff}`,
        {
          phoneNumber: payload.phoneNumber,
          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          positionId: payload.positionId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then(() => {
        payload.page = 1;
        dispatch("getAllStaffs", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  ChangeStaffRole({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ChangeStaffRole}`,
        {
          positionId: payload.positionId,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("getAllStaffs", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getStaffActivities({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${StaffActivities}?page=${page}`,
        {
          staffId: payload.staffId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setStaffActivities", res.data.Staffs);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
