import {
  NewParcel,
  GetAllParcels,
  NewParcelCount,
  StaffGetEstimatedPrice,
  officeParcelPayment,
  GetUserParcel,
  GetNearByRiderToParcelPickUp,
  AssignParcelToRider,
  SearchOnlineCustomer,
  SearchOfflineCustomer,
  SearchParcel,
  ReAssignParcel,
  GetStationParcel,
  StationReceivedParcel,
  GetStationParcelCount,
  ParcelStationPickup,
  AddIssueTicket,
  GetParcel,
  ParcelPayment,
} from "../APIs";

import axios from "axios";

const state = {
  newParcels: [],
  AllParcels: [],
  ParcelCount: 0,
  estimatedPrice: null,
  printParcel: "",
  userPackage: [],
  customerStatus: "online",
  NearByRider: [],
  searchParcel: [],
  searchUsers: [],
  StationParcelCount: 0,
  dialog: false,
  PayLinkParcel: null,
};
const getters = {
  dialog() {
    return state.dialog;
  },
  nearByRider(state) {
    return state.NearByRider;
  },
  newParcels(state) {
    return state.newParcels;
  },
  AllParcels(state) {
    return state.AllParcels;
  },
  ParcelCount(state) {
    return state.ParcelCount;
  },
  StationParcelCount(state) {
    return state.StationParcelCount;
  },
  estimatedPrice(state) {
    return state.estimatedPrice;
  },
  printParcel(state) {
    return state.printParcel;
  },
  userPackage(state) {
    return state.userPackage;
  },
  customerStatus(state) {
    return state.customerStatus;
  },
  searchParcel() {
    return state.searchParcel;
  },
  searchUsers() {
    return state.searchUsers;
  },
  PayLinkParcel(state) {
    return state.PayLinkParcel;
  },
};
const mutations = {
  setDialog(state, payload) {
    state.dialog = payload;
  },
  setNewParcel(state, payload) {
    state.newParcels = payload;
  },
  setCustomerStatus(state, payload) {
    state.customerStatus = payload;
  },
  setTotalItems(state, payload) {
    state.totalItems = payload;
  },
  setAllParcels(state, payload) {
    state.AllParcels = payload;
  },
  setNewParcelCount(state, payload) {
    state.ParcelCount = payload;
  },
  setEstimatedPrice(state, payload) {
    state.estimatedPrice = payload;
  },
  setPrintParcel(state, payload) {
    state.printParcel = payload;
  },
  setUserPackage(state, payload) {
    state.userPackage = payload;
  },
  setNearByRider(state, payload) {
    state.NearByRider = payload;
  },
  setSearchUsers(state, payload) {
    state.searchUsers = payload;
  },
  setSearch(state, payload) {
    state.searchParcel = payload;
  },
  setStationParcelCount(state, payload) {
    state.StationParcelCount = payload;
  },
  setPayLinkParcel(state, payload) {
    state.PayLinkParcel = payload;
  },
};

const actions = {
  ParcelPayment({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${ParcelPayment}`, {
        parcelId: payload.parcelId,
        reference: payload.reference,
      })
      .then(() => {
        dispatch("GetParcel", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
        console.log(err.response);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  GetParcel({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${GetParcel}`, {
        parcelId: payload.parcelId,
      })
      .then((res) => {
        commit("setPayLinkParcel", res.data.parcel);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
        console.log(err.response);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  GetNewParcel({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .get(`${NewParcel}?page=${page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setNewParcel", res.data.package);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetAllParcels({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${GetAllParcels}?page=${page}`,
        {
          status: payload.status,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setAllParcels", res.data.package);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  getStationParcel({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${GetStationParcel}?page=${page}`,
        {
          status: payload.status,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setAllParcels", res.data.packages);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetStationParcelCount({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetStationParcelCount}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setStationParcelCount", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  NewParcelCount({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${NewParcelCount}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setNewParcelCount", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  officePackagePickUpEstimatedPrice({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${StaffGetEstimatedPrice}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setEstimatedPrice", res.data.officePackage);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  officeParcelPayment({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${officeParcelPayment}`,
        {
          packageId: payload.packageId,
          PaymentMethod: payload.PaymentMethod,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        dispatch("GetNewParcel", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setPrintParcel", res.data.Package);
        payload.route.push({ name: "PrintParcel" });
        commit("setEstimatedPrice", null);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getUserPackages({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${GetUserParcel}?page=${page}`,
        {
          userId: payload.userId,
          status: payload.status,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setUserPackage", res.data.package);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetNearByRiderToParcelPickUp({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    let page = payload.page || 1;
    axios
      .put(
        `${GetNearByRiderToParcelPickUp}?page=${page}`,
        {
          latitude: payload.latitude,
          longitude: payload.longitude,
          vehicleType: payload.vehicleType,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setNearByRider", res.data.riders);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  assignPackageToRider({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${AssignParcelToRider}`,
        {
          riderId: payload.riderId,
          parcelId: payload.parcelId,
          action: payload.action,
          handOverTo: payload.handOverTo,
          stationDeliveryId: payload.stationDeliveryId,
          riderPayoutPercenter: payload.riderPayoutPercenter,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("GetNewParcel", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  searchParcel({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SearchParcel}`,
        {
          keywords: payload.keywords,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSearch", res.data.Parcel);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  searchOfflineCustomer({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);

    axios
      .put(
        `${SearchOfflineCustomer}`,
        {
          keywords: payload.keywords,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSearchUsers", res.data.Users);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  searchOnlineCustomer({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SearchOnlineCustomer}`,
        {
          keywords: payload.keywords,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSearchUsers", res.data.Users);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  stationReceivedParcel({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${StationReceivedParcel}`,
        {
          parcelId: payload.parcelId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("GetAllParcels", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  ReAssignParcel({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ReAssignParcel}`,
        {
          parcelId: payload.parcelId,
          riderId: payload.riderId,
          reAssignSharingPercentage: payload.reAssignSharingPercentage,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("GetAllParcels", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  parcelStationPickup({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ParcelStationPickup}`,
        {
          parcelId: payload.parcelId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        payload.status = "receivedParcel";
        dispatch("getStationParcel", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  issueTicket({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${AddIssueTicket}`,
        {
          parcelId: payload.parcelId,
          message: payload.message,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setDialog", false);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
