import {
  Login,
  GetAllUsers,
  GetAllOfflineUser,
  GetUserWalletTransaction,
} from "../APIs";

import axios from "axios";

const state = {
  loading: false,
  TableLoading: false,
  ServerMessage: "",
  snackbar: false,
  getUser: null,
  Token: "",
  totalItems: 1,
  Users: [],
  userWalletTransaction: [],
};
const getters = {
  Users(state) {
    return state.Users;
  },
  TableLoading(state) {
    return state.TableLoading;
  },
  getToken(state) {
    return state.Token;
  },
  getUser(state) {
    return state.getUser;
  },
  getLoading(state) {
    return state.loading;
  },
  ServerMessage(state) {
    return state.ServerMessage;
  },
  Snackbar(state) {
    return state.snackbar;
  },
  totalItems() {
    return state.totalItems;
  },
  userWalletTransaction(state) {
    return state.userWalletTransaction;
  },
};
const mutations = {
  setUsers(state, payload) {
    state.Users = payload;
  },
  setUserWalletTransaction(state, payload) {
    state.userWalletTransaction = payload;
  },
  setTableLoading(state, payload) {
    state.TableLoading = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  setUser(state, payload) {
    state.getUser = payload;
  },
  setSignOut(state, payload) {
    state.User = "";
    state.getUser = "";
    state.Token = "";
    payload.route.push({ name: "login" });
  },
  setToken(state, payload) {
    if (payload) {
      state.tokenExpired = /*8.64e7*/ 20000 + Date.now();
    }
    state.Token = payload;
  },
  setServerMessage(state, payload) {
    state.ServerMessage = payload;
  },
};
const actions = {
  Login({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${Login}`, {
        phoneNumber: payload.phoneNumber,
        password: payload.password,
      })
      .then((res) => {
        commit("setUser", res.data.profile);
        commit("setToken", res.data.token);
        if (payload.redirect) {
          payload.route.push({ path: payload.redirect });
        } else {
          payload.route.push({ name: "newParcel" });
        }
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getAllUsers({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllUsers}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setUsers", res.data.users);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  getAllOfflineUsers({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllOfflineUser}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setUsers", res.data.users);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  getUserWalletTransaction({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetUserWalletTransaction}`,
        {
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setUserWalletTransaction", res.data.walletTransact);
        commit("setStaffWalletTransactionTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
