import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "login",
      meta: { forVisitors: true },
      component: () => import("../pages/Login.vue"),
    },
    {
      path: "/paylink/:id",
      name: "paylink",
      component: () => import("../pages/Paylink.vue"),
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      meta: { forAuth: true },
      component: () => import("../pages/Dashboard.vue"),
      children: [
        {
          path: "/newParcel",
          name: "newParcel",
          meta: { forAuth: true },
          component: () => import("../pages/Newparcel.vue"),
        },
        {
          path: "/AllParcel",
          name: "AllParcel",
          meta: { forAuth: true },
          component: () => import("../pages/AllParcel.vue"),
        },
        {
          path: "/StationParcel",
          name: "StationParcel",
          meta: { forAuth: true },
          component: () => import("../pages/StationParcel.vue"),
        },
        {
          path: "/AddParcel",
          name: "AddParcel",
          meta: { forAuth: true },
          component: () => import("../pages/AddParcel.vue"),
        },
        {
          path: "/PrintParcel",
          name: "PrintParcel",
          meta: { forAuth: true },
          component: () => import("../pages/PrintParcel.vue"),
        },
        {
          path: "/ManageRider",
          name: "ManageRider",
          meta: { forAuth: true },
          component: () => import("../pages/ManageRider.vue"),
        },
        {
          path: "/AddRider",
          name: "AddRider",
          meta: { forAuth: true },
          component: () => import("../pages/AddRider.vue"),
        },
        {
          path: "/Customers",
          name: "Customers",
          meta: { forAuth: true },
          component: () => import("../pages/Customers.vue"),
        },
        {
          path: "/MessagingSystem",
          name: "MessagingSystem",
          meta: { forAuth: true },
          component: () => import("../pages/MessagingSystem.vue"),
        },
        {
          path: "/Revenue",
          name: "Revenue",
          meta: { forAuth: true },
          component: () => import("../pages/Revenue.vue"),
        },
        {
          path: "/ManageStaff",
          name: "ManageStaff",
          meta: { forAuth: true },
          component: () => import("../pages/ManageStaff.vue"),
        },
        {
          path: "/SystemActivities",
          name: "SystemActivities",
          meta: { forAuth: true },
          component: () => import("../pages/SystemActivities.vue"),
        },
        {
          path: "/Inventory",
          name: "Inventory",
          meta: { forAuth: true },
          component: () => import("../pages/Inventory.vue"),
        },
        {
          path: "/Settings",
          name: "Settings",
          meta: { forAuth: true },
          component: () => import("../pages/Settings.vue"),
        },
        {
          path: "/FulfillmentCenter",
          name: "FulfillmentCenter",
          meta: { forAuth: true },
          component: () => import("../pages/FulfillmentCenter.vue"),
        },
        {
          path: "/ManageAsset",
          name: "ManageAsset",
          meta: { forAuth: true },
          component: () => import("../pages/ManageAsset.vue"),
        },
      ],
    },
    {
      path: "*",
      name: "page404",
      component: () => import("../pages/Page404"),
    },
  ],
});
