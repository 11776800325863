export default function(Vue) {
  let authenticatedUser = {};

  Vue.auth = {
    setToken(token) {
      this.$store.commit("setAuthUser", token);
    },
    getToken() {
      if (this.$store.getters.getToken) {
        return null;
      }
      return this.$store.$store.getters.getToken;
    },

    destroyToken() {
      this.$store.commit("setAuthUser", null);
    },

    isAuthenticated() {
      let valid = this.getToken();
      if (!valid) {
        return false;
      } else return false;
    },
    setAuthenticatedUser(data) {
      authenticatedUser = data;
    },

    getAuthenticatedUser() {
      return authenticatedUser;
    },
  };

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      },
    },
  });
}
