import {
  SendSMSToOnlineCustomers,
  SendSingleSMS,
  SendPushNotification,
} from "../APIs";

import axios from "axios";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  sendSMSToOnlineCustomers({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SendSMSToOnlineCustomers}`,
        {
          deliverTO: payload.deliverTO,
          message: payload.message,
          userId: payload.userId,
        },

        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  SendSingleSMS({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SendSingleSMS}`,
        {
          phoneNumber: payload.phoneNumber,
          message: payload.message,
          parcelId: payload.parcelId,
        },

        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  SendPushNotification({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SendPushNotification}`,
        {
          deliverTO: payload.deliverTO,
          message: payload.message,
          title: payload.title,
          userId: payload.userId,
        },

        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
