//export const Domain = "https://shippay.ng/api/v1";
//export const DomainImage = "https://shippay.ng";
//export const DomainSocket = "https://shippay.ng/socket.io";

export const Domain = "https://paxrify.com/api/v1";
export const DomainImage = "https://paxrify.com";
export const DomainSocket = "https://paxrify.com/socket.io";

//export const Domain = "http://localhost:3030/api/v1";
//export const DomainImage = "http://localhost:3030/api/";
//export const DomainSocket = "http://localhost:3030";

export const Login = `${Domain}/staff/Login`;
export const RestPassword = `${Domain}/staff/RestPassword`;
export const ConfirmRestPassword = `${Domain}/staff/ConfirmRestPassword`;
export const GetAllRider = `${Domain}/staff/GetAllRider`;
export const AddRider = `${Domain}/staff/AddRider`;
export const GetAllStaffs = `${Domain}/staff/GetAllStaffs`;
export const ActivateAndDisableStaff = `${Domain}/staff/ActivateAndDisableStaff`;
export const GetAvailableStaffPosition = `${Domain}/staff/GetAvailableStaffPosition`;
export const GetRoles = `${Domain}/staff/GetRoles`;
export const CreateStaffPosition = `${Domain}/staff/CreateStaffPosition`;
export const AddStaff = `${Domain}/staff/AddStaff`;
export const ChangeStaffRole = `${Domain}/staff/ChangeStaffPosition`;

export const GetRiderWalletHistory = `${Domain}/rider/GetRiderWalletHistory`;
export const ActivateAndDeactivateRider = `${Domain}/rider/ActivateAndDeactivateRider`;
export const TransferRider = `${Domain}/rider/TransferRider`;
export const RiderOfflinePayout = `${Domain}/rider/RiderOfflinePayout`;
export const freezeRiderWallet = `${Domain}/rider/freezeRiderWallet`;

export const NewParcel = `${Domain}/parcel/NewParcel`;
export const GetAllParcels = `${Domain}/parcel/GetAllParcels`;
export const NewParcelCount = `${Domain}/parcel/NewParcelCount`;
export const StaffGetEstimatedPrice = `${Domain}/parcel/StaffGetEstimatedPrice`;
export const officeParcelPayment = `${Domain}/parcel/officeParcelPayment`;
export const GetUserParcel = `${Domain}/parcel/GetUserParcel`;
export const GetAllPackageByDateRange = `${Domain}/parcel/getAllPackageByDateRange`;
export const GetPackageByDateRange = `${Domain}/parcel/GetPackageByDateRange`;
export const GetParcelByDateRange = `${Domain}/parcel/GetParcelByDateRange`;
export const GetNearByRiderToParcelPickUp = `${Domain}/parcel/GetNearByRiderToParcelPickUp`;
export const AssignParcelToRider = `${Domain}/parcel/AssignParcelToRider`;
export const ReAssignParcel = `${Domain}/parcel/ReAssignParcel`;
export const GetStationParcel = `${Domain}/parcel/GetStationParcel`;
export const StationReceivedParcel = `${Domain}/parcel/StationReceivedParcel`;
export const GetStationParcelCount = `${Domain}/parcel/GetStationParcelCount`;
export const ParcelStationPickup = `${Domain}/parcel/ParcelStationPickup`;
export const AddIssueTicket = `${Domain}/parcel/AddIssueTicket`;

export const GetAllFulfillmentCenter = `${Domain}/fulfillmentCenter/AllFulfillmentCenter`;
export const SwitchCenter = `${Domain}/fulfillmentCenter/SwitchCenter`;
export const GetFulfillmentCenter = `${Domain}/fulfillmentCenter/GetFulfillmentCenter`;
export const UpdateFulfillmentCenter = `${Domain}/fulfillmentCenter/UpdateFulfillmentCenter`;
export const ActivateOrDeactivateDeliveryType = `${Domain}/fulfillmentCenter/ActivateOrDeactivateDeliveryType`;
export const createFulfillmentCenter = `${Domain}/fulfillmentCenter/AddFulfillmentCenter`;
export const ActivateOrDeactivateCenter = `${Domain}/fulfillmentCenter/ActivateOrDeactivateCenter`;
export const GetCenterStaffs = `${Domain}/fulfillmentCenter/GetCenterStaffs`;

export const GetAllUsers = `${Domain}/auth/GetAllUser`;
export const GetAllOfflineUser = `${Domain}/auth/GetAllOfflineUser`;

export const GetUserWalletTransaction = `${Domain}/wallet/GetUserWalletTransaction`;

export const SendSMSToOnlineCustomers = `${Domain}/messageSystem/SendSMSToOnlineCustomers`;
export const SendPushNotification = `${Domain}/messageSystem/SendPushNotification`;
export const SendSingleSMS = `${Domain}/messageSystem/SendSingleSMS`;

export const FundOnlineUserWallet = `${Domain}/wallet/FundOnlineUserWallet`;

export const GetAllWalletTransactions = `${Domain}/revenue/GetAllWalletTransactions`;

export const StaffActivities = `${Domain}/systemActivities/StaffActivities`;
export const GetAllActivities = `${Domain}/systemActivities/GetAllActivities`;

export const GetFulfillmentCenterInventory = `${Domain}/inventory/GetFulfillmentCenterInventory`;
export const AssignStaffToInventory = `${Domain}/inventory/AssignStaffToInventory`;
export const GetAllInventory = `${Domain}/inventory/GetAllInventory`;
export const AddInventory = `${Domain}/inventory/AddInventory`;

export const GetFulfillmentCenterExpenses = `${Domain}/expense/GetFulfillmentCenterExpenses`;
export const GetAllExpenses = `${Domain}/expense/GetAllExpenses`;
export const AddExpense = `${Domain}/expense/AddExpense`;
export const AddAsset = `${Domain}/expense/AddAsset`;
export const LinkRiderToAsset = `${Domain}/expense/LinkRiderToAsset`;
export const UnLinkAsset = `${Domain}/expense/UnLinkAsset`;
export const GetAssets = `${Domain}/expense/GetAssets`;
export const UploadAssetDoc = `${Domain}/expense/UploadAssetDoc`;

export const SearchOnlineCustomer = `${Domain}/search/SearchOnlineCustomer`;
export const SearchOfflineCustomer = `${Domain}/search/SearchOfflineCustomer`;
export const SearchParcel = `${Domain}/search/SearchParcel`;

export const GetParcel = `${Domain}/bot/GetParcel`;
export const ParcelPayment = `${Domain}/bot/ParcelPayment`;
