import {
  GetAllFulfillmentCenter,
  TransferRider,
  SwitchCenter,
  GetAllActivities,
  GetFulfillmentCenterInventory,
  AssignStaffToInventory,
  GetAllInventory,
  GetFulfillmentCenterExpenses,
  GetAllExpenses,
  AddExpense,
  AddInventory,
  GetFulfillmentCenter,
  UpdateFulfillmentCenter,
  ActivateOrDeactivateDeliveryType,
  createFulfillmentCenter,
  ActivateOrDeactivateCenter,
  GetCenterStaffs,
  UnLinkAsset,
  LinkRiderToAsset,
  AddAsset,
  GetAssets,
  UploadAssetDoc,
} from "../APIs";

import axios from "axios";

const state = {
  AllFulfillmentCenter: [],
  activities: [],
  expense: [],
  inventory: [],
  FulfillmentCenter: null,
  centerStaff: [],
  Asset: [],
};
const getters = {
  AllFulfillmentCenter(state) {
    return state.AllFulfillmentCenter;
  },
  systemActivities(state) {
    return state.activities;
  },
  expense(state) {
    return state.expense;
  },
  inventory(state) {
    return state.inventory;
  },
  FulfillmentCenter(state) {
    return state.FulfillmentCenter;
  },
  centerStaff(state) {
    return state.centerStaff;
  },
  getAssets(state) {
    return state.Asset;
  },
};
const mutations = {
  setAllFulfillmentCenter(state, payload) {
    state.AllFulfillmentCenter = payload;
  },
  setActivities(state, payload) {
    state.activities = payload;
  },
  setInventory(state, payload) {
    state.inventory = payload;
  },
  setExpense(state, payload) {
    state.expense = payload;
  },
  setFulfillmentCenter(state, payload) {
    state.FulfillmentCenter = payload;
  },
  setCenterStaff(state, payload) {
    state.centerStaff = payload;
  },
  setAsset(state, payload) {
    state.Asset = payload;
  },
};
const actions = {
  getAllFulfillmentCenter({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllFulfillmentCenter}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setAllFulfillmentCenter", res.data.FulfillmentCenter);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  TransferRider({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${TransferRider}`,
        {
          centerId: payload.centerId,
          riderId: payload.riderId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAllRider", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  TransferStaff({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SwitchCenter}`,
        {
          centerId: payload.centerId,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAllStaffs", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  switchCenter({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${SwitchCenter}`,
        {
          centerId: payload.centerId,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setSignOut", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getAllActivities({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllActivities}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setActivities", res.data.StaffActivity);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  addInventory({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddInventory}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getInventory", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  addExpense({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddExpense}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getExpense", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getAllExpenses({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllExpenses}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setExpense", res.data.Expense);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getExpense({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetFulfillmentCenterExpenses}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setExpense", res.data.Expense);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getAllInventory({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllInventory}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setInventory", res.data.Inventory);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  assignStaffToInventory({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${AssignStaffToInventory}`,
        {
          inventoryId: payload.inventoryId,
          staffId: payload.staffId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        payload.page = 1;
        dispatch("getInventory", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getInventory({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetFulfillmentCenterInventory}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setInventory", res.data.Inventory);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getFulfillmentCenter({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetFulfillmentCenter}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setFulfillmentCenter", res.data.FulfillmentCenter);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  updateFulfillmentCenter({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${UpdateFulfillmentCenter}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getFulfillmentCenter", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  ActivateOrDeactivateDeliveryType({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ActivateOrDeactivateDeliveryType}`,
        {
          vanService: payload.vanService,
          bikeService: payload.bikeService,
          carService: payload.carService,
          truckService: payload.truckService,
          planeService: payload.planeService,
          vanFlat: payload.vanFlat,
          bikeFlat: payload.bikeFlat,
          carFlat: payload.carFlat,
          truckFlat: payload.truckFlat,
          planeFlat: payload.planeFlat,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getFulfillmentCenter", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  CreateFulfillmentCenter({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${createFulfillmentCenter}`,
        {
          locationName: payload.locationName,
          platformPercentageCut: payload.platformPercentageCut,
          weight: payload.weight,
          latitude: payload.latitude,
          longitude: payload.longitude,
          vanService: payload.vanService,
          vanKmCharge: payload.vanKmCharge,
          vanMinCharge: payload.vanMinCharge,
          vanMinKM: payload.vanMinKM,
          vanMaxKM: payload.vanMaxKM,
          vanReduceChargesAfterKm: payload.vanReduceChargesAfterKm,
          vanReduceFeePerKm: payload.vanReduceFeePerKm,
          bikeService: payload.bikeService,
          bikeKmCharge: payload.bikeKmCharge,
          bikeMinCharge: payload.bikeMinCharge,
          bikeMinKM: payload.bikeMinKM,
          bikeMaxKM: payload.bikeMaxKM,
          bikeReduceChargesAfterKm: payload.bikeReduceChargesAfterKm,
          bikeReduceFeePerKm: payload.bikeReduceFeePerKm,
          carService: payload.carService,
          carKmCharge: payload.carKmCharge,
          carMinCharge: payload.carMinCharge,
          carMinKM: payload.carMinKM,
          carMaxKM: payload.carMax,
          carReduceChargesAfterKm: payload.carReduceChargesAfterKm,
          carReduceFeePerKm: payload.carReduceFeePerKm,
          truckService: payload.truckService,
          truckKmCharge: payload.truckKmCharge,
          truckMinCharge: payload.truckMinCharge,
          truckMinKM: payload.truckMinKM,
          truckMaxKM: payload.truckMaxKM,
          truckReduceChargesAfterKm: payload.truckReduceChargesAfterKm,
          truckReduceFeePerKm: payload.truckReduceFeePerKm,
          planeService: payload.planeService,
          planeKmCharge: payload.planeKmCharge,
          planeMinCharge: payload.planeMinCharge,
          planeMinKM: payload.planeMinKM,
          planeMaxKM: payload.planeMaxKM,
          planeReduceChargesAfterKm: payload.planeReduceChargesAfterKm,
          planeReduceFeePerKm: payload.planeReduceFeePerKm,
          returnPercentageFees: payload.returnPercentageFees,
          bikeFlat: payload.bikeFlat,
          bikeFlatFees: payload.bikeFlatFees,
          carFlat: payload.carFlat,
          carFlatFees: payload.carFlatFees,
          vanFlat: payload.vanFlat,
          vanFlatFees: payload.vanFlatFees,
          truckFlat: payload.truckFlat,
          truckFlatFees: payload.truckFlatFees,
          planeFlat: payload.planeFlat,
          planeFlatFees: payload.planeFlatFees,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getFulfillmentCenter", payload);
        payload.route.push({ name: "FulfillmentCenter" });
      })
      .catch((err) => {
        console.log(err.response.data);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  ActivateOrDeactivateCenter({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ActivateOrDeactivateCenter}`,
        {
          centerId: payload.centerId,
          active: payload.active,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAllFulfillmentCenter", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  getCenterStaffs({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetCenterStaffs}?page=${payload.page}`,
        {
          centerId: payload.centerId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setStaffWalletTransaction", res.data.count);
        commit("setCenterStaff", res.data.Staffs);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  AddAsset({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddAsset}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAsset", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getAsset({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAssets}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setAsset", res.data.PartnerAsset);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  UploadAssetDoc({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    const formData = new FormData();
    formData.append("image", payload.image);
    formData.append("title", payload.title);
    formData.append("assetId", payload.assetId);
    axios
      .put(`${UploadAssetDoc}`, formData, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAsset", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  UnLinkAsset({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${UnLinkAsset}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAsset", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  LinkRiderToAsset({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${LinkRiderToAsset}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAsset", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
