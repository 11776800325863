import Vue from "vue";
import Vuex from "vuex";
import User from "./user";
import parcel from "./parcel";
import staff from "./staff";
import Center from "./Center";
import MessagingSystem from "./MessagingSystem";
import Revenue from "./Revenue";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    parcel,
    staff,
    Center,
    MessagingSystem,
    Revenue,
  },
  strict: process.env.NODE_ENV !== "production",
});
