import {
  FundOnlineUserWallet,
  GetAllWalletTransactions,
  GetParcelByDateRange,
  GetAllPackageByDateRange,
  GetPackageByDateRange,
} from "../APIs";

import axios from "axios";

const state = {
  todayReport: [],
  todayReportData: [],
  oneMonthReport: [],
  oneMonthReportData: [],
  Report: [],
  AllWalletTransaction: [],
  WalletTransactionChart: [],
  ParcelTrips: [],
  ParcelTripsChart: [],
  Partners: [],
};
const getters = {
  getPartners(state) {
    return state.Partners;
  },
  GetParcelTrips(state) {
    return state.ParcelTrips;
  },
  ParcelTripsChart(state) {
    return state.ParcelTripsChart;
  },
  todayReport(state) {
    return state.todayReport;
  },
  AllWalletTransaction(state) {
    return state.AllWalletTransaction;
  },
  WalletTransactionChart(state) {
    return state.WalletTransactionChart;
  },
  todayReportData(state) {
    return state.todayReportData;
  },
  oneMonthReportData(state) {
    return state.oneMonthReportData;
  },
  Report(state) {
    return state.Report;
  },
  oneMonthReport(state) {
    return state.oneMonthReport;
  },
};
const mutations = {
  setTodayReport(state, payload) {
    const size = 5;
    const items = payload.slice(0, size);
    let report = [];
    items.forEach((element) => {
      report.push(element.shippingFee);
    });
    for (let i = 0; report.length < 5; i++) {
      report.push(0);
    }
    state.todayReport = report;
    state.todayReportData = payload;
  },
  setOneMonthReport(state, payload) {
    const items = payload.slice(Math.max(payload.length - 5, 0));
    let report = [];
    items.forEach((element) => {
      report.push(element.shippingFee);
    });
    for (let i = 0; report.length < 5; i++) {
      report.push(0);
    }
    state.oneMonthReport = report;
    state.oneMonthReportData = payload;
  },
  setWalletTransactionChart(state, payload) {
    const items = payload.slice(Math.max(payload.length - 8, 0));
    let report = [];
    items.forEach((element) => {
      report.push(element.amount);
    });
    for (let i = 0; report.length < 5; i++) {
      report.push(0);
    }
    state.WalletTransactionChart = report;
  },
  setReport(state, payload) {
    state.Report = payload;
  },
  setAllWalletTransaction(state, payload) {
    state.AllWalletTransaction = payload;
  },
  setParcelTrips(state, payload) {
    state.ParcelTrips = payload;
  },
  setPartners(state, payload) {
    state.Partners = payload;
  },
  setParcelTripsChart(state, payload) {
    const items = payload.slice(Math.max(payload.length - 8, 0));
    let report = [];
    items.forEach((element) => {
      report.push(parseFloat(element.distance.slice(0, -2)));
    });
    for (let i = 0; report.length < 5; i++) {
      report.push(0);
    }
    state.ParcelTripsChart = report;
  },
};
const actions = {
  fundOnlineUserWallet({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${FundOnlineUserWallet}`,
        {
          userId: payload.userId,
          amount: payload.amount,
          description: payload.description,
          walletId: payload.walletId,
        },

        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("getAllUsers", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getAllWalletTransactions({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllWalletTransactions}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setAllWalletTransaction", res.data.transaction);
        commit("setWalletTransactionChart", res.data.transaction);
      })
      .catch((err) => {
        console.log(err.response);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  getAllPackageByDateRange({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetAllPackageByDateRange}?page=${payload.page}`,
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setTotalItems", res.data.count);
        if (payload.status == "today") {
          commit("setTodayReport", res.data.packages);
        }
        if (payload.status == "onMonth") {
          commit("setOneMonthReport", res.data.packages);
        }
        if (payload.status == "others") {
          commit("setReport", res.data.packages);
        }
      })
      .catch((err) => {
        console.log(err.response);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetParcelTrips({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetParcelByDateRange}?page=${payload.page}`,
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setTotalItems", res.data.count);
        commit("setParcelTrips", res.data.packages);
        commit("setParcelTripsChart", res.data.packages);
      })
      .catch((err) => {
        console.log(err.response);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  getPackageByDateRange({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetPackageByDateRange}?page=${payload.page}`,
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setTotalItems", res.data.count);
        if (payload.status == "today") {
          commit("setTodayReport", res.data.packages);
        }
        if (payload.status == "onMonth") {
          commit("setOneMonthReport", res.data.packages);
        }
        if (payload.status == "others") {
          commit("setReport", res.data.packages);
        }
      })
      .catch((err) => {
        console.log(err.response);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
