<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <Loading v-if="loading" />
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
import Loading from "./components/Loading";

export default {
  name: "App",

  components: {
    Snackbar,
    Loading,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },

  data: () => ({
    //
  }),
};
</script>
